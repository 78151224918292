import { DateTime } from 'luxon'
import { useCallback, useEffect } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../../../components/atoms/button/Button'
import Text from '../../../../../components/atoms/text/Text'
import ListItem from '../../../../../components/molecules/listItem/ListItem'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../../components/molecules/screen/Screen'
import ToggleButton from '../../../../../components/molecules/toggleButton/ToggleButton'
import routes from '../../../../../config/routes'
import { useProfile } from '../../../../../modules/auth/hooks'
import {
  useAvailableSlots,
  useMeetingRoomReservationById,
  useUpdateMeetingRoomReservationMutation,
} from '../../../../../modules/meetingRoom/hooks'
import { useHolidays, useReservationByVoucherId } from '../../../../../modules/seats/hooks'
import { getBookDay, getBookType } from '../../../../../modules/seats/utils'
import { usePurchasedVoucher } from '../../../../../modules/vouchers/hooks'
import { useBookAssistant } from '../../../../../providers/bookAssistant'
import { TYPES } from '../../../../../providers/bookAssistant/types'
import { useCardTransition } from '../../../../../providers/cardTransition/cardTransition'
import { VARIANTS } from '../../../../../types/Components.types'
import DatePicker from './components/datePicker/DatePicker'
import styles from './MeetingRoomChangeSlotScreen.module.css'

interface IParams {
  reservationId: string
}

const MeetingRoomChangeSlotScreen = () => {
  const history = useHistory()
  const { reservationId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: meetingRoomReservation, isLoading: isLoadingMeetingRoomReservation } = useMeetingRoomReservationById({
    userId: profile?.id,
    reservationId,
  })
  const { data: reservation, isLoading: isLoadingReservation } = useReservationByVoucherId({
    userId: profile?.id,
    voucherId: meetingRoomReservation?.Users_MeetingRoom_Pool?.UsersVoucherId,
  })
  const { data: voucher, isLoading: isLoadingVoucher } = usePurchasedVoucher({
    userId: profile?.id,
    voucherId: meetingRoomReservation?.Users_MeetingRoom_Pool?.UsersVoucherId,
  })
  const isLoading = isLoadingProfile || isLoadingVoucher || isLoadingReservation || isLoadingMeetingRoomReservation

  const bookType = getBookType({ voucher })
  const minHourAvailable = reservation ? DateTime.fromISO(reservation?.Users_Seats[0]?.reserved_from).hour || 9 : 0
  const maxHourAvailable = reservation ? DateTime.fromISO(reservation?.Users_Seats[0]?.reserved_to).hour - 1 || 21 : 0
  const { setType, type, day, hours, setHours, clear, setDay } = useBookAssistant()
  const { data: holidays } = useHolidays()
  const { data: slots } = useAvailableSlots({ date: day ? DateTime.fromJSDate(day).toISODate() : undefined })

  const { openCard } = useCardTransition()
  const totalHours = 1

  const updateMeetingRoomReservation = useUpdateMeetingRoomReservationMutation()

  const handleContinueClick = useCallback(() => {
    updateMeetingRoomReservation.mutate(
      {
        userId: profile!.id,
        reservationId: meetingRoomReservation!.id,
        type,
        day: day!,
        hour: hours[0],
      },
      {
        onSuccess: () => {
          clear?.()
          history.replace(routes.home)
        },
      },
    )
  }, [updateMeetingRoomReservation, profile, meetingRoomReservation, type, day, hours, clear, history])

  const handleDateSelectorClick = useCallback(() => {
    let maxDate
    if (bookType === TYPES.DAY) {
      return
    } else if (bookType === TYPES.MONTH) {
      maxDate = getBookDay({ reservation: reservation! }).endOf('month')
    }
    openCard?.({ Component: <DatePicker maxDate={maxDate} disabledDates={holidays} /> })
  }, [bookType, openCard, holidays, reservation])

  const handleToggleHour = useCallback(
    (hour: string) => {
      const idx = hours.findIndex((h) => h === hour)
      if (idx < 0) {
        if (hours.length === totalHours) {
          return
        }
        setHours({ hours: [...hours, hour] })
      } else {
        setHours({ hours: hours.filter((h) => h !== hour) })
      }
    },
    [hours, setHours, totalHours],
  )

  useEffect(() => {
    if (!reservation) {
      return
    }

    setType({ type: TYPES.MEETING_ROOM })
    if (bookType === TYPES.DAY) {
      setDay({ day: getBookDay({ reservation }).toJSDate() })
    }
  }, [setType, reservation, setDay, bookType])

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.flamingo,
        title: 'Cambiar reserva',
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <ListItem label="Para cuando quieres cambiar tu reservar">
            <Button
              className={styles.button}
              autoSize
              variant={VARIANTS.secondary}
              inverted
              onClick={handleDateSelectorClick}
              disabled={bookType === TYPES.DAY}
            >
              <Text size="sm" variant={VARIANTS.secondary} weight="500">
                {day ? DateTime.fromJSDate(day).toLocaleString(DateTime.DATE_MED) : 'Seleccionar'}
              </Text>
            </Button>
          </ListItem>

          {day && slots && (
            <ListItem
              className={styles.hoursOptions}
              label="¿A que horas quieres cambiar la reserva de la sala de reuniones?"
            >
              <div className={styles.hours}>
                {slots.map((slot) => {
                  const selected = !!hours.find((hour) => hour === slot.hour)
                  const slotHour = DateTime.fromISO(slot.hour).hour
                  const isDisabled =
                    slot.status !== 'free' || slotHour < minHourAvailable || slotHour > maxHourAvailable
                  return (
                    <ToggleButton
                      className={styles.hour}
                      isActive={selected}
                      key={slot.hour}
                      disabled={isDisabled}
                      onClick={() => handleToggleHour(slot.hour)}
                    >
                      <Text size="sm" variant={selected ? VARIANTS.white : VARIANTS.secondary} weight="500">
                        {DateTime.fromISO(slot.hour).toFormat('HH:mm')}
                      </Text>
                    </ToggleButton>
                  )
                })}
              </div>
            </ListItem>
          )}
        </div>

        <div className={styles.actions}>
          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            onClick={handleContinueClick}
            disabled={hours.length === 0}
          >
            Cambiar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default MeetingRoomChangeSlotScreen
