import { DateObjectUnits, DateTime } from 'luxon'
import { IShift, SHIFTS } from '../../providers/bookAssistant/shifts'
import { TYPES } from '../../providers/bookAssistant/types'
import { ISeat, IReservatedSeat, IUserSeat } from '../../types/app.types'
import SeatsAPI from './api'

const groupUserSeats = (seats: IUserSeat[]) => {
  return Object.values(seats).reduce<{ monthly: IUserSeat[]; rest: IUserSeat[] }>(
    (grouped, seat) => {
      if (seat.type === TYPES.MONTH) {
        grouped.monthly.push(seat)
      } else {
        grouped.rest.push(seat)
      }
      return grouped
    },
    { monthly: [], rest: [] },
  )
}

const groupByVoucher = (seats: IUserSeat[]) => {
  return Object.values(seats).reduce<{ [key: string]: IUserSeat[] }>((grouped, seat) => {
    if (!grouped[seat.userVoucher]) {
      grouped[seat.userVoucher] = []
    }

    grouped[seat.userVoucher].push(seat)
    return grouped
  }, {})
}

const sortUserSeats = (seats: IUserSeat[]) => {
  return Object.values(seats).sort((a, b) => {
    return DateTime.fromISO(a.reserved_from).toMillis() - DateTime.fromISO(b.reserved_from).toMillis()
  })
}

export const getSeats = async ({ queryParams }: { queryParams?: URLSearchParams }): Promise<ISeat[]> => {
  const response = await SeatsAPI.getSeats({ queryParams })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const getReservatedSeats = async ({ userId }: { userId: string }): Promise<IReservatedSeat[]> => {
  const response = await SeatsAPI.getUserBookedSeats({ userId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  const seats: IReservatedSeat[] = await response.json()
  return seats.reduce<IReservatedSeat[]>((sanitizedReservatedSeats, reservation) => {
    const { Users_Seats = [] } = reservation

    const { monthly, rest } = groupUserSeats(sortUserSeats(Users_Seats))

    const groupedByVoucher = groupByVoucher(monthly)
    Object.keys(groupedByVoucher).forEach((userVoucher) => {
      const seats = groupedByVoucher[userVoucher]
      const from = seats[0]
      const to = seats[seats.length - 1]
      sanitizedReservatedSeats.push({
        ...reservation,
        Users_Seats: seats,
        type: from.type,
        from: from.reserved_from,
        to: to.reserved_from,
        seat: from,
      })
    })

    rest.forEach((seat) => {
      sanitizedReservatedSeats.push({
        ...reservation,
        Users_Seats: [seat],
        type: seat.type,
        seat,
      })
    })
    return sanitizedReservatedSeats
  }, [])
}

export const getHolidays = async (): Promise<DateObjectUnits[]> => {
  const response = await SeatsAPI.getHolidays()
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const reservateSeat = async ({
  userId,
  type,
  date,
  shift,
  seatId,
  seatType,
  months,
}: {
  userId: string
  type: string
  date: Date
  shift: Partial<IShift>
  seatId: string
  seatType: string
  months: number
}): Promise<{ status: number; params: string }> => {
  const sanitizedDate = DateTime.fromJSDate(date).toISODate()
  const params = {
    type,
    schedule: {
      from: shift.from ?? SHIFTS.morning.from,
      to: shift.to ?? SHIFTS.morning.to,
    },
    seatId,
    seatType,
    months,
    userId,
    date: sanitizedDate,
  }

  const response = await SeatsAPI.bookSeat(params)
  if (response.status !== 200 && response.status !== 402) {
    throw new Error(response.status.toString())
  }

  return { status: response.status, params: btoa(JSON.stringify(params)) }
}

export const updateReservation = async ({
  userId,
  date,
  bookId,
  seatId,
}: {
  userId: string
  date: Date
  bookId: string
  seatId: string
}): Promise<{ status: number }> => {
  const sanitizedDate = DateTime.fromJSDate(date).toISODate()
  const response = await SeatsAPI.updateReservation({
    userId,
    bookId,
    seatId,
    date: sanitizedDate,
  })
  if (response.status !== 200 && response.status !== 402) {
    throw new Error(response.status.toString())
  }

  return { status: response.status }
}

export const reservateCoworking = async ({
  userId,
  days,
  attachments,
}: {
  userId: string
  days: Date[]
  attachments: File[]
}): Promise<{ status: number }> => {
  const from = DateTime.fromJSDate(days[0]).toISODate()
  const to = DateTime.fromJSDate(days[days.length - 1]).toISODate()
  const response = await SeatsAPI.reserveCoworking({
    userId,
    from,
    to,
    attachments,
  })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return { status: response.status }
}

export const getWorkmates = async ({
  userId,
  seatId,
}: {
  userId: string
  seatId: string
}): Promise<IReservatedSeat[]> => {
  const response = await SeatsAPI.getBookWorkmates({ userId, seatId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const getWifiPassword = async ({
  userId,
  seatId,
}: {
  userId: string
  seatId: string
}): Promise<{ user: string; pass: string }> => {
  const response = await SeatsAPI.getWifiPassword({ userId, seatId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return await response.json()
}

export const openCoworking = async ({
  userId,
  seatId,
  coordinates,
  dummyCoordinates,
}: {
  userId: string
  seatId: string
  coordinates: GeolocationPosition
  dummyCoordinates: boolean
}): Promise<{ status: number }> => {
  const response = await SeatsAPI.openCoworking({ userId, seatId, coordinates, dummyCoordinates })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return { status: response.status }
}
