import { DateTime } from 'luxon'
import { TYPES } from '../../providers/bookAssistant/types'
import { IMeetingRoomHour, IMeetingRoomReservation, IMeetingRoomSlot } from '../../types/app.types'
import MeetingRoomAPI from './api'

export const getMeetingRoomReservations = async ({
  userId,
}: {
  userId: string
}): Promise<IMeetingRoomReservation[]> => {
  const response = await MeetingRoomAPI.getReservations({ userId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }
  const reservations: IMeetingRoomReservation[] = await response.json()
  return reservations.map((reservation) => {
    reservation.type = TYPES.MEETING_ROOM
    return reservation
  })
}

export const getMeetingRoomHours = async ({ userId }: { userId: string }): Promise<IMeetingRoomHour[]> => {
  const response = await MeetingRoomAPI.getAvailableHours({ userId })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }
  return await response.json()
}

export const getAvailableHours = async ({ userId }: { userId: string }): Promise<number> => {
  const hours = await getMeetingRoomHours({ userId })
  return hours.reduce((total, hour) => {
    if (hour.isConsumed) {
      return total
    }
    return total + (hour?.availableHours || 0)
  }, 0)
}

export const getAvailableSlots = async ({ date }: { date: string }): Promise<IMeetingRoomSlot[]> => {
  const response = await MeetingRoomAPI.getAvailableSlots({ date })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }
  const slots: IMeetingRoomSlot[] = await response.json()
  return slots.filter((slot) => {
    const hour = DateTime.fromISO(slot.hour).hour
    return hour >= 9 && hour < 21
  })
}

export const reservateMeetingRoom = async ({
  type,
  userId,
  day,
  hours,
  bookId,
}: {
  type: string
  userId: string
  day: Date
  hours: string[]
  bookId: string
}): Promise<{ status: number; params: string }> => {
  const sanitizedDate = DateTime.fromJSDate(day).toISODate()
  const response = await MeetingRoomAPI.reserve({
    userId,
    hours: hours.map((hour) => {
      const date = DateTime.fromISO(hour)
      return {
        date: sanitizedDate,
        from: date.hour,
        to: date.hour + 1,
      }
    }),
  })

  if (response.status === 402) {
    return {
      status: 402,
      params: btoa(
        JSON.stringify({
          type,
          userId,
          date: sanitizedDate,
          bookId,
          hours,
        }),
      ),
    }
  }

  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  // const json = await response.json()
  // if (json.some((reservation: { code: number }) => reservation.code === 402)) {
  //   return {
  //     status: 402,
  //     params: btoa(
  //       JSON.stringify({
  //         type,
  //         userId,
  //         date: sanitizedDate,
  //         bookId,
  //         hours,
  //       }),
  //     ),
  //   }
  // }

  return { status: response.status, params: '' }
}

export const updateMeetingRoomReservation = async ({
  userId,
  reservationId,
  type,
  day,
  hour,
}: {
  userId: string
  reservationId: string
  type: string
  day: Date
  hour: string
}): Promise<{ status: number }> => {
  const sanitizedDate = DateTime.fromJSDate(day).toISODate()
  const date = DateTime.fromISO(hour)
  const response = await MeetingRoomAPI.updateReservation({
    userId,
    reservationId,
    date: sanitizedDate,
    from: date.hour,
    to: date.hour + 1,
  })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return {
    status: response.status,
  }
}

export const openCoworking = async ({
  userId,
  meetingRoomId,
  coordinates,
  dummyCoordinates,
}: {
  userId: string
  meetingRoomId: string
  coordinates: GeolocationPosition
  dummyCoordinates: boolean
}): Promise<{ status: number }> => {
  const response = await MeetingRoomAPI.openCoworking({ userId, meetingRoomId, coordinates, dummyCoordinates })
  if (response.status !== 200) {
    throw new Error(response.status.toString())
  }

  return { status: response.status }
}
