import { useMutation, useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import React from 'react'
import { toast } from 'react-toastify'
import {
  getHolidays,
  getReservatedSeats,
  getSeats,
  getWifiPassword,
  getWorkmates,
  openCoworking,
  reservateCoworking,
  reservateSeat,
  updateReservation,
} from '.'
import { useBookAssistant } from '../../providers/bookAssistant'
import { SHIFTS } from '../../providers/bookAssistant/shifts'
import { useProfile } from '../auth/hooks'
import { CoworkingQueryClient } from '../CoworkingQueryClient'
import { orderBooksByDate } from './utils'

export const useSeats = ({
  queryParams,
  options,
}: {
  queryParams?: URLSearchParams
  options?: { enabled: boolean }
}) => {
  return useQuery({
    queryKey: ['seats', queryParams?.toString() ?? ''],
    queryFn: () => getSeats({ queryParams }),
    ...(options ?? {}),
  })
}

export const useSeatById = ({ queryParams, seatId }: { queryParams?: URLSearchParams; seatId?: string | null }) => {
  const { data: seats, ...rest } = useSeats({ queryParams, options: { enabled: !!seatId && !!queryParams } })

  const seat = React.useMemo(() => {
    if (!seats) {
      return null
    }
    return seats.find((seat) => seat.id === seatId)
  }, [seats, seatId])

  return { data: seat, ...rest }
}

export const useReservatedSeats = ({ userId }: { userId?: string }) => {
  return useQuery({
    queryKey: ['reservated-seats', userId],
    queryFn: () => getReservatedSeats({ userId: userId! }),
    enabled: !!userId,
  })
}

export const useReservatedSeatById = ({ userId, seatId }: { userId?: string; seatId: string }) => {
  const { data: reservatedSeats, ...rest } = useReservatedSeats({ userId })
  const reservatedSeat = React.useMemo(() => {
    if (!reservatedSeats) {
      return null
    }
    return reservatedSeats.find((reservation) => {
      const { seat } = reservation
      return seat.id === seatId
    })
  }, [reservatedSeats, seatId])

  return { data: reservatedSeat, ...rest }
}

export const useTodayReservatedSeats = ({ userId }: { userId?: string }) => {
  const { data: reservatedSeats, ...rest } = useReservatedSeats({ userId })
  const todayReservatedSeats = React.useMemo(() => {
    if (!reservatedSeats) {
      return null
    }
    return reservatedSeats.filter((reservation) => {
      const { Users_Seats = [] } = reservation
      return Users_Seats.some((seat) => {
        const from = DateTime.fromISO(seat.reserved_from)
        const to = DateTime.fromISO(seat.reserved_to)

        const isToday = from.startOf('day').equals(DateTime.local().startOf('day'))
        const isOnTime = to.diffNow('hour')
        return isToday && isOnTime.hours > 0
      })
    })
  }, [reservatedSeats])

  return { data: todayReservatedSeats, ...rest }
}

export const useUpcomingReservatedSeats = ({ userId }: { userId?: string }) => {
  const { data: reservatedSeats, ...rest } = useReservatedSeats({ userId })
  const upcomingReservatedSeats = React.useMemo(() => {
    if (!reservatedSeats) {
      return null
    }
    return reservatedSeats.sort(orderBooksByDate)
  }, [reservatedSeats])

  return { data: upcomingReservatedSeats, ...rest }
}

export const useReservationByVoucherId = ({ userId, voucherId }: { userId?: string; voucherId?: string }) => {
  const { data: reservatedSeats, ...rest } = useReservatedSeats({ userId })
  const reservation = React.useMemo(() => {
    if (!reservatedSeats) {
      return null
    }
    return reservatedSeats.find((reservation) => reservation.seat.userVoucher === voucherId)
  }, [reservatedSeats, voucherId])

  return { data: reservation, ...rest }
}

export const useHolidays = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['holidays'],
    queryFn: getHolidays,
  })

  const holidays = React.useMemo(() => {
    if (!data) {
      return []
    }

    return Object.values(data).map((holiday) => DateTime.fromObject(holiday).toJSDate())
  }, [data])

  return { data: holidays, ...rest }
}

export const useSeatsQueryParams = () => {
  const { type, day, shift } = useBookAssistant()
  return React.useMemo(() => {
    const params: {
      from: string
      to: string
      type: string
      date?: string
      fromDate?: string
    } = {
      from: String(shift.from ?? SHIFTS.morning.from),
      to: String(shift.to ?? SHIFTS.morning.to),
      type,
      fromDate: String(false),
    }
    if (day) {
      params.date = DateTime.fromJSDate(day).toISODate()
    }

    return new URLSearchParams(params)
  }, [day, shift?.from, shift?.to, type])
}

export const useReservationWorkmates = ({ userId, seatId }: { userId?: string; seatId?: string }) => {
  return useQuery({
    queryKey: ['reservated-seats', userId, seatId, 'workmates'],
    queryFn: () => getWorkmates({ userId: userId!, seatId: seatId! }),
    enabled: !!userId && !!seatId,
  })
}

export const useWifiPassword = () => {
  const { data: profile } = useProfile()
  const { data: todayReservations } = useTodayReservatedSeats({
    userId: profile?.id,
  })
  const todayReservation = todayReservations?.[0]
  return useQuery({
    queryKey: ['reservated-seats', profile?.id, 'wifi-password'],
    queryFn: () => getWifiPassword({ userId: profile?.id!, seatId: todayReservation?.seat.id! }),
    enabled: !!profile?.id && !!todayReservation,
  })
}

export const useReservateSeatMutation = () => {
  return useMutation({
    mutationFn: reservateSeat,
    onSuccess: ({ status }, { userId }) => {
      if (status !== 402) {
        toast.success('Tu reserva ha sido confirmada')
      }
      CoworkingQueryClient.invalidateQueries({ queryKey: ['reservated-seats', userId] })
      CoworkingQueryClient.invalidateQueries({ queryKey: ['purchased-vouchers', userId] })
    },
    onError: () => {
      toast.error('Oops! No hemos podido confirmar tu reserva')
    },
  })
}

export const useUpdateReservateSeatMutation = () => {
  return useMutation({
    mutationFn: updateReservation,
    onSuccess: ({ status }, { userId }) => {
      if (status !== 402) {
        toast.success('Tu reserva se ha cambiado correctamente')
      }
      CoworkingQueryClient.invalidateQueries({ queryKey: ['reservated-seats', userId] })
      CoworkingQueryClient.invalidateQueries({ queryKey: ['purchased-vouchers', userId] })
    },
    onError: () => {
      toast.error('Oops! No hemos podido cambiar tu reserva')
    },
  })
}

export const useReservateCoworkingMutation = () => {
  return useMutation({
    mutationFn: reservateCoworking,
    onSuccess: () => {
      toast.success('Nos pondremos en contacto contigo después de revisar la documentación aportada')
    },
    onError: () => {
      toast.error('Oops! No hemos podido confirmar tu reserva')
    },
  })
}

export const useOpenCoworkingMutation = () => {
  return useMutation({
    mutationFn: openCoworking,
    onError: () => {
      toast.error('Oops! No hemos podido abrir la puerta')
    },
  })
}
