import { DateTime } from 'luxon'
import React, { useCallback, useEffect } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../../../components/atoms/button/Button'
import Text from '../../../../../components/atoms/text/Text'
import ListItem from '../../../../../components/molecules/listItem/ListItem'
import LoadingData from '../../../../../components/molecules/loadingData/LoadingData'
import Screen from '../../../../../components/molecules/screen/Screen'
import ToggleButton from '../../../../../components/molecules/toggleButton/ToggleButton'
import routes from '../../../../../config/routes'
import { useProfile } from '../../../../../modules/auth/hooks'
import {
  useAvailableHours,
  useAvailableSlots,
  useReservateMeetingRoomMutation,
} from '../../../../../modules/meetingRoom/hooks'
import { useHolidays, useReservatedSeatById } from '../../../../../modules/seats/hooks'
import { getBookDay, getBookType } from '../../../../../modules/seats/utils'
import { usePurchasedVoucher } from '../../../../../modules/vouchers/hooks'
import { useBookAssistant } from '../../../../../providers/bookAssistant'
import { TYPES } from '../../../../../providers/bookAssistant/types'
import { useCardTransition } from '../../../../../providers/cardTransition/cardTransition'
import { VARIANTS } from '../../../../../types/Components.types'
import styles from './BookMeetingRoomScreen.module.css'
import DatePicker from './components/datePicker/DatePicker'

interface IParams {
  bookId: string
}

const BookMeetingRoomScreen = () => {
  const { bookId } = useParams<IParams>()
  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: reservation, isLoading: isLoadingReservation } = useReservatedSeatById({
    userId: profile?.id,
    seatId: bookId,
  })
  const { data: voucher, isLoading: isLoadingVoucher } = usePurchasedVoucher({
    userId: profile?.id,
    voucherId: reservation?.seat.userVoucher,
  })
  const { data: availableHours, isLoading: isLoadingAvailableHours } = useAvailableHours({ userId: profile?.id })
  const { setType, type, day, setDay, hours, setHours, clear } = useBookAssistant()
  const history = useHistory()
  const { openCard } = useCardTransition()
  const totalHours = (availableHours ?? 0) + 1
  const needToBuyVoucher = React.useMemo(() => {
    if (!availableHours) {
      return false
    }
    return availableHours === 0 || hours.length > availableHours
  }, [availableHours, hours.length])
  const bookType = getBookType({ voucher })
  const minHourAvailable = reservation ? DateTime.fromISO(reservation?.Users_Seats[0]?.reserved_from).hour || 9 : 0
  const maxHourAvailable = reservation ? DateTime.fromISO(reservation?.Users_Seats[0]?.reserved_to).hour - 1 || 21 : 0
  const { data: holidays } = useHolidays()
  const { data: slots } = useAvailableSlots({ date: day ? DateTime.fromJSDate(day).toISODate() : undefined })

  const isLoading = isLoadingProfile || isLoadingReservation || isLoadingVoucher || isLoadingAvailableHours

  const reservateMeetingRoomMutation = useReservateMeetingRoomMutation()

  const handleContinueClick = useCallback(() => {
    reservateMeetingRoomMutation.mutate(
      { type, day: day!, hours, bookId, userId: profile!.id },
      {
        onSuccess: ({ status, params }) => {
          if (status === 402) {
            history.push(`${routes.book.vouchers}?b=${params}`)
            return
          }

          clear?.()
          history.replace(routes.book.new.meetingsRoom.success)
        },
      },
    )
  }, [reservateMeetingRoomMutation, type, day, hours, bookId, profile, clear, history])

  const handleDateSelectorClick = useCallback(() => {
    let maxDate
    if (bookType === TYPES.DAY) {
      return
    } else if (bookType === TYPES.MONTH) {
      maxDate = getBookDay({ reservation: reservation! }).endOf('month')
    }
    openCard?.({ Component: <DatePicker maxDate={maxDate} disabledDates={holidays} /> })
  }, [openCard, reservation, bookType, holidays])

  const handleToggleHour = useCallback(
    (hour: string) => {
      const idx = hours.findIndex((h) => h === hour)
      if (idx < 0) {
        if (hours.length === totalHours) {
          return
        }
        setHours({ hours: [...hours, hour] })
      } else {
        setHours({ hours: hours.filter((h) => h !== hour) })
      }
    },
    [hours, setHours, totalHours],
  )

  useEffect(() => {
    if (!reservation) {
      return
    }
    setType({ type: TYPES.MEETING_ROOM })
    if (bookType === TYPES.DAY) {
      setDay({ day: getBookDay({ reservation }).toJSDate() })
    }
  }, [setType, setDay, bookType, reservation])

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.flamingo,
        title: 'Nueva reserva',
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <ListItem label="Cuando quieres reservar">
            <Button
              className={styles.button}
              autoSize
              variant={VARIANTS.secondary}
              inverted
              onClick={handleDateSelectorClick}
              disabled={bookType === TYPES.DAY}
            >
              <Text size="sm" variant={VARIANTS.secondary} weight="500">
                {day ? DateTime.fromJSDate(day).toLocaleString(DateTime.DATE_MED) : 'Seleccionar'}
              </Text>
            </Button>
          </ListItem>

          {day && (
            <div className={styles.infoWrapper}>
              <CircularProgressbar
                className={styles.chart}
                value={totalHours - hours.length}
                maxValue={totalHours}
                text={`${totalHours - hours.length}h`}
                styles={{
                  path: {
                    stroke: 'rgba(26, 145, 215)',
                  },
                  text: {
                    fill: 'rgba(26, 145, 215)',
                  },
                }}
              />

              <div>
                <Text size="sm">Este es el número de horas disponible para reservar la sala de reuniones</Text>

                {needToBuyVoucher && (
                  <Text className={styles.helpText} size="s" weight="600">
                    Tendrás que comprar un bono
                  </Text>
                )}
              </div>
            </div>
          )}

          {day && slots && (
            <ListItem className={styles.hoursOptions} label="¿A que horas quieres reservar la sala de reuniones?">
              <div className={styles.hours}>
                {slots.map((slot) => {
                  const selected = hours.find((hour) => hour === slot.hour)
                  const slotHour = DateTime.fromISO(slot.hour).hour
                  const isDisabled =
                    slot.status !== 'free' || slotHour < minHourAvailable || slotHour > maxHourAvailable
                  return (
                    <ToggleButton
                      className={styles.hour}
                      isActive={!!selected}
                      key={slot.hour}
                      disabled={isDisabled}
                      onClick={() => handleToggleHour(slot.hour)}
                    >
                      <Text size="sm" variant={selected ? VARIANTS.white : VARIANTS.secondary} weight="500">
                        {DateTime.fromISO(slot.hour).toFormat('HH:mm')}
                      </Text>
                    </ToggleButton>
                  )
                })}
              </div>
            </ListItem>
          )}
        </div>

        <div className={styles.actions}>
          <Button
            variant={VARIANTS.primary}
            className={styles.action}
            onClick={handleContinueClick}
            disabled={hours.length === 0}
          >
            Reservar
          </Button>
        </div>
      </div>
    </Screen>
  )
}

export default BookMeetingRoomScreen
